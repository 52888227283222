<template>
  <div>
    <NavBar class="nav-bar" :iconColor="'#9B9B9B'">
      <div slot="title">{{ shopInfo.shop_name }}</div>
    </NavBar>
    <div class="search-margin">
      <div class="search" v-if="tabType !== 4">
        <div class="search-box">
          <van-icon name="search" color="#959595" size="20" />
          <input
            v-model="inputVal"
            type="text"
            placeholder="搜索本店商品"
            class="search-input"
          />
        </div>
        <div class="search-text" @click="search">搜索</div>
      </div>
    </div>
    <div v-show="bottomActive === 1">
      <!-- 视频 -->
      <div class="video-box-top">
        <div class="video-box" v-if="shopInfo.video_info && shopInfo.video_info.file_url">
          <video
            class="video"
            ref="refVideo"
            :src="shopInfo.video_info.file_url"
            controls
            v-show="videoShow"
            @ended="ended"
          ></video>
          <div class="cover-box" v-show="!videoShow" @click="startPlay">
            <van-icon
              class="play-icon"
              name="play-circle-o"
              color="#fff"
              size="50"
            />
            <img :src="shopInfo.video_info.cover_url" class="cover-img" />
          </div>
        </div>
      </div>
      <!-- 店铺信息 -->
      <div class="shop-page">
        <div class="shop-layout">
          <div class="shop-layout">
            <div class="shop-img">
              <img :src="shopInfo.shop_logo || shopImgUrl" />
            </div>
            <div class="shop-info">
              <span class="title">{{ shopInfo.shop_name }}</span>
              <div class="evaluation">
                <span>综合体验</span>
                <van-rate
                  v-model="shopInfo.shop_score"
                  void-icon="star"
                  void-color="#E3E3E3"
                  gutter="1"
                  class="van--rate"
                  readonly
                />
              </div>
            </div>
          </div>
          <div class="enshrine-button">
            <div
              class="enshrine-ok"
              :class="{ 'enshrine-highlight': shopInfo.is_collect * 1 !== 0 }"
              @click="setShopCollect"
            >
              {{ shopInfo.is_collect * 1 !== 0 ? "取消收藏" : "收藏" }}
            </div>
          </div>
        </div>
      </div>
      <div class="address-info">
        <span class="address-title">详细地址：</span>
        <span class="address-details"
          >{{ shopInfo.city_name || "" }}{{ shopInfo.address || "" }}</span
        >
        <div class="address-box" @click="setNavigation">
          <img src="~@/assets/common/the_map_icon@2x.png" />
        </div>
      </div>
      <Tab @tabChange="tabChange" @screenChange="screenChange"></Tab>
      <Cheap
        :shopId="shopId"
        v-if="tabType === 4"
        @getGoodsList="getGoodsList"
      ></Cheap>
      <div class="list-title" v-if="tabType === 4">活动商品</div>
      <GoodsList ref="refList" :shopId="shopId"></GoodsList>
    </div>
    <AllClass ref="refClass" @selectClass="selectClass" v-show="bottomActive === 2"></AllClass>
    <!-- 底部导航 -->
    <div class="bottom-nav">
      <div class="btn-layout" @click="bottomNav(1)">
        <img
          src="~@/assets/shop-home/store-home.png"
          alt=""
          class="icon-img"
          v-if="bottomActive === 1"
        />
        <img
          src="~@/assets/shop-home/contact_seller-h.png"
          alt=""
          class="icon-img"
          v-else
        />

        <span :style="{ color: bottomActive === 1 ? '#DE3421' : 'black' }">
          店铺主页
        </span>
      </div>
      <div class="btn-layout" @click="bottomNav(2)">
        <img
          src="~@/assets/shop-home/more.png"
          alt=""
          class="icon-img"
          v-if="bottomActive === 2"
        />
        <img
          src="~@/assets/shop-home/more-h.png"
          alt=""
          class="icon-img"
          v-else
        />
        <span :style="{ color: bottomActive === 2 ? '#DE3421' : 'black' }">
          商品分类
        </span>
      </div>
      <div class="contact-seller btn-layout a" @click="bottomNav(3)">
        <img src="~@/assets/shop-home/contact_seller.png" class="icon-img" />
        <span> 联系店铺 </span>
      </div>
    </div>
    <Navigation
      ref="Navigation"
      v-if="showNavigation"
      :detailInfo="shopInfo"
      @closeNavigation="closeNavigation"
    ></Navigation>
  </div>
</template>

<script>
import AllClass from "./moudles/all-class/all-class.vue";
import Tab from "./moudles/tab/tab";
import NavBar from "@/layout/nav-bar/nav-bar";
import Navigation from "@/components/map/navigation";
import GoodsList from "./moudles/goods-list/goods-list";
import Cheap from "./moudles/cheap/cheap";
import { getShopDetails, setShopCollect } from "@/api/shop";
import { GoodsClass } from "@/api/goods.js";
import { Toast } from "vant";
export default {
  name: "shop-home",
  components: {
    NavBar,
    Navigation,
    AllClass,
    GoodsList,
    Tab,
    Cheap,
  },
  data() {
    return {
      videoShow: false,
      showNavigation: false,
      detailInfo: {},
      shopImgUrl: require("@/assets/common/shop_logo.png"),
      shopId: this.$route.query.shopId * 1 || 0,
      shopInfo: { id: 0, is_collect: 0, video_info: {} },
      evaluate: 1,
      listClass: [],
      controls: false,
      bottomActive: 1,
      listFormData: {},
      inputVal: "",
      tabType: "",
    };
  },
  mounted() {
    this.getShopInfo();
    this.GoodsClass();
  },
  methods: {
    search() {
      this.listFormData.good_name = this.inputVal;
      this.listFormData.is_new = "0";
      this.listFormData.is_sift = "0";
      this.listFormData.order_by = 1;
      this.listFormData.class_id = "0";
      this.$refs.refClass.setOption();
      this.$refs.refList.getData(this.listFormData);
      this.bottomActive = 1;
    },
    selectClass(item) {
      this.listFormData.good_name = "";
      this.inputVal = "";
      this.bottomActive = 1;
      this.listFormData.class_id = item.id;
      if (item.id === 0) {
        this.listFormData.class_id = "0";
      } else {
        this.listFormData.class_id = item.id;
      }
      this.$refs.refList.getData(this.listFormData);
    },
    tabChange(type) {
      this.tabType = type;
      switch (type) {
        case 1:
          this.listFormData.is_new = "0";
          this.listFormData.is_sift = "0";
          this.listFormData.order_by = 1;
          break;
        case 2:
          this.listFormData.is_new = "0";
          this.listFormData.is_sift = 1;
          this.listFormData.order_by = 1;
          break;
        case 3:
          this.listFormData.is_new = 1;
          this.listFormData.is_sift = "0";
          this.listFormData.order_by = 1;
          break;
        default:
          break;
      }
      if (type !== 4) {
        this.$refs.refList.getData(this.listFormData);
      }
    },
    getGoodsList(ids, isAll) {
      this.$refs.refList.initCoupon(ids, isAll);
    },
    screenChange(type) {
      this.listFormData.order_by = type;
      this.$refs.refList.getData(this.listFormData);
    },
    ended() {
      this.videoShow = false;
    },
    startPlay() {
      this.videoShow = true;
      this.$refs.refVideo.play();
    },
    bottomNav(active) {
      if (active === 3) {
        this.$router.push({
          name: "information",
          query: {
            shopId: this.shopId,
            groupType: 2,
          },
        });
      } else {
        this.bottomActive = active;
      }
    },
    setNavigation() {
      this.showNavigation = true;
      this.$refs.Navigation.showMap();
    },
    closeNavigation() {
      this.showNavigation = false;
    },
    onChange(num) {
      if (num === 1) {
        this.isColor = true;
      } else {
        this.isColor = false;
      }
    },

    async getShopInfo() {
      const res = await getShopDetails({ shop_id: this.shopId });
      if (res.code * 1 == 1) {
        this.shopInfo = res.data;
        console.log(this.shopInfo)
      }
    },
    async setShopCollect() {
      //设置收藏
      let is_collect = this.shopInfo.is_collect * 1 == 1 ? 0 : 1;
      const res = await setShopCollect({
        shop_id: this.shopId,
        is_collect: is_collect,
      });
      if (res.code * 1 == 1) {
        Toast.success(res.msg);
        this.shopInfo.is_collect = is_collect;
      }
    },
    async GoodsClass() {
      const res = await GoodsClass();
      this.listClass = res.data;
      // console.log("商品分栏--------", res);
    },
  },
};
</script>

<style lang="less" scoped>
.list-title {
  margin: 13px;
  font-size: 19px;
  font-weight: 600;
}
.search-margin {
  margin-top: 53px;
}
.search {
  display: flex;
  align-items: center;
  padding: 9px 13px;

  background-color: #fff;
  .search-box {
    flex: 1;
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
    height: 39px;
    margin-right: 10px;
    padding: 0 12px;
    border-radius: 39px;
    .search-input {
      width: 100%;
      margin-left: 7px;
      font-size: 18px;
      border: none;
      background-color: transparent;
    }
  }
  .search-text {
    font-size: 18px;
  }
}
.video-box {
  width: 100vw;
  height: 233px;
  background-color: #000;
  .video {
    width: 100%;
    height: 100%;
  }
  .cover-box {
    position: relative;
    width: 100%;
    height: 100%;
    .play-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .cover-img {
      width: 100%;
      height: 100%;
    }
  }
}
// vant组件按钮
/deep/.van-icon-star {
  font-size: 12px;
}
.van-button {
  height: 32px;
}

/deep/.van-cell {
  padding: 12px 26px;
}
.max-box {
  padding: 0;
}
// vant组件菜单栏

/deep/.van-ellipsis {
  font-weight: 500;
  font-size: 18px;
}

/deep/.van-sidebar-item__text {
  font-size: 17px;
}
/deep/.van-tree-select__item {
  font-size: 17px;
}
/deep/.van-cell__title {
  font-size: 17px;
}
.nav-bar {
  background-color: #fff;
  border-bottom: 1px solid #ededed;
}

img {
  width: 100%;
  display: block;
}

.shop-page {
  padding: 13px 26px 20px 26px;
  margin-top: 14px;
  border-bottom: 1px solid #f5f5f5;
  background-color: #fff;
}

.shop-layout {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .shop-img {
    width: 52px;
    img {
      width: 52px;
      height: 52px;
      border-radius: 6px;
    }
  }

  .shop-info {
    padding-left: 10px;
    width: 220px;
    .title {
      font-weight: 500;
      font-size: 18px;
    }
    .evaluation {
      position: relative;
      // display: flex;
      // align-items: center;
      // width: 64%;
      display: table;
      margin-top: 10px;
      padding: 2px 4px;
      border-radius: 4px;
      background-color: #fdf2f4;
      span {
        font-size: 12px;
        padding-right: 4px;
      }
    }
  }
}

.address-info {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 16px;
  background-color: #fff;
  padding: 14px 12px;
  box-sizing: border-box;
  span {
    flex-shrink: 0;
  }
  .address-title {
    color: #aaaaaa;
    margin-right: 10px;
  }
  .address-details {
    display: block;
    width: 240px;
    display: -webkit-box; /**对象作为伸缩盒子模型展示**/
    -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
    -webkit-line-clamp: 2; /**显示的行数**/
    overflow: hidden; /**隐藏超出的内容**/
    word-break: break-all; /** 英语字母设定问题，它不会换行在代码中加入强制换行 可以解决这个问题**/
  }
  .address-box {
    flex-shrink: 0;
    width: 20px;
    margin-left: 10px;
  }
}
.a {
  color: #6f6f6f;
}
.bottom-nav {
  position: fixed;
  bottom: 0;
  display: flex;
  width: 100%;
  height: 57px;
  background-color: #fff;
  font-size: 13px;
  .btn-layout {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &:active {
      background-color: rgb(243, 243, 243);
    }
  }
  .icon-img {
    display: block;
    width: 31px;
    height: 31px;
  }
}
.enshrine-button {
  .enshrine-ok {
    width: 78px;
    height: 32px;
    background: linear-gradient(260deg, #ed301d 0%, #fe5876 100%);
    opacity: 1;
    border-radius: 19px;
    color: #fff;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
  }
  .enshrine-highlight {
    width: 78px;
    height: 32px;
    border: 1px solid #aaaaaa;
    opacity: 1;
    background: transparent;
    border-radius: 19px;
    color: #656565;
  }
}
/deep/.van-popup {
  background-color: #f5f5f5;
}
/deep/.van-popup--top {
  height: 100%;
}
</style>
