<template>
  <div class="cheap">
    <ul>
      <li class="discounts-list" v-for="(item, index) in list" :key="item.id">
        <div class="discounts-number" v-if="item.num > 0">{{ item.num }}张</div>
        <div class="list-left-box">
          <div class="list-left">
            <div class="discounts-price">
              <Price
                :price="item.money"
                integerStyle="26"
                decimalStyle="13"
              ></Price>
            </div>
            <div class="overflow-subtract">
              {{
                item.full_type === 1 ? `满${item.full_money}元可减` : "无门槛"
              }}
            </div>
          </div>
        </div>
        <div class="list-right">
          <div class="describe-text">{{ item.name }}</div>
          <div class="right-bottom">
            <div class="time">{{ item.time_str }}</div>
            <div
              class="get-btn"
              v-if="item.is_receive === 0"
              @click="getDiscounts(item, index)"
            >
              立即领取
            </div>
            <div
              class="fast-use"
              v-else-if="item.status !== 2"
              @click="goToUrl(item)"
            >
              立即使用
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div class="all" v-if="listReplace.length > 3" @click="all">
      <div class="all-text">全部</div>
      <van-icon name="arrow-down" v-if="list.length <= 3" />
      <van-icon name="arrow-up" v-else />
    </div>
  </div>
</template>

<script>
import { getShopcoupon } from "@/api/shop.js";
import { getDiscounts } from "@/api/discounts.js";
import { Toast } from "vant";
export default {
  props: ["shopId"],
  data() {
    return {
      showBlank: false,
      list: [],
      listReplace: [],
    };
  },
  mounted() {
    this.getShopcoupon();
  },
  methods: {
    all() {
      if (this.list.length === this.listReplace.length) {
        this.list.splice(3);
      } else {
        this.list = JSON.parse(JSON.stringify(this.listReplace));
      }
    },
    goToUrl(item) {
      this.$router.push({
        name: "discounts",
        query: {
          couponId: item.coupon_id,
          grantId: item.grant_id,
        },
      });
    },
    async getDiscounts(item, index) {
      const res = await getDiscounts({
        coupon_id: item.coupon_id,
        grant_id: item.grant_id,
        type: 1,
      });
      if (res.code === 1) {
        Toast.success(res.msg);
        this.list[index].is_receive = 1;
      }
    },
    async getShopcoupon() {
      const res = await getShopcoupon({ shop_id: this.shopId });
      if (res.code === 1) {
        this.list = res.data.list;
        this.listReplace = JSON.parse(JSON.stringify(res.data.list));
        this.$emit("getGoodsList", res.data.ids, res.data.is_all);
        if (this.list.length > 3) {
          this.list.splice(3);
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.cheap {
  border-top: 1px solid rgb(243, 243, 243);
  padding: 16px 13px;
  background-color: #fff;
}
ul {
  .discounts-list {
    position: relative;
    display: flex;
    background: url("../../../../../assets/discounts/bg1@2x.png") no-repeat
      center / 100% 100%;
    margin-bottom: 10px;
    &:last-child {
      margin: 0;
    }
    .expire {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 0;
      width: 68px;
      height: auto;
    }
    .no-take {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 0;
      width: 68px;
      height: auto;
    }
    .discounts-number {
      position: absolute;
      width: 35px;
      height: 17px;
      text-align: center;
      line-height: 17px;
      color: #fff;
      background: linear-gradient(90deg, #ffd871 0%, #f69f1c 100%);
      border-radius: 2px;
    }
  }
}
.list-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 118px;
  height: 106px;
  color: #ed301d;
  .discounts-price {
    display: flex;
    align-items: center;
    font-size: 16px;
  }
  .overflow-subtract {
    font-size: 13px;
    font-weight: 500;
  }
}
.list-right {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  height: 106px;
  padding: 12px;
  box-sizing: border-box;
  .describe-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    word-break: break-all;
    font-size: 17px;
  }
  .get-btn {
    width: 83px;
    height: 34px;
    background: rgb(245, 76, 64);
    border-radius: 34px;
    text-align: center;
    line-height: 34px;
    font-size: 16px;
    color: #fff;
  }
  .expire {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 68px;
    height: auto;
  }
}
.right-bottom {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  .fast-use {
    width: 83px;
    height: 34px;
    border: 1px solid #f54c40;
    border-radius: 34px;
    font-size: 16px;
    text-align: center;
    line-height: 34px;
    color: #ed301d;
  }
}
.time {
  color: #999999;
  font-size: 12px;
}
.deadline {
  text-align: right;
  color: #999999;
  font-size: 13px;
}
.all {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 13px;
  font-size: 17px;
  color: #999999;
  .all-text {
    margin-right: 10px;
  }
}
</style>