<template>
  <div class="classify-layout" style="position: relative">
    <!-- 第一级 -->
    <ul class="one-ul-layout">
      <li
        class="one-ul-layout-li"
        v-for="(item, index) in dataList"
        :class="{ 'is-show-color': active === index }"
        :key="index"
        @click="onClick(index, item)"
      >
        <span>{{ item.class_name }}</span>
      </li>
    </ul>
    <!-- 第二级 -->
    <ul class="two-ul-layout">
      <li
        class="two-ul-li"
        v-for="(item, index) in twoList"
        :key="index"
        :class="{ 'is-show-mark': active === index }"
      >
        <div class="two-ul-title">{{ item.class_name }}</div>
        <!-- 第三级 -->
        <ul class="three-ul-layout">
          <li
            class="three-ul-li"
            :class="{ 'text-color': item.id === classId }"
            v-for="(item, index) in item.child_class"
            :key="index"
            @click="goToGoods(item)"
          >
            {{ item.class_name }}
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { GoodsClass } from "@/api/goods.js";
export default {
  name: "classify",
  components: {},
  data() {
    return {
      dataList: [],
      twoList: [],
      active: 0,
      classId: 0,
      show: false,
    };
  },
  mounted() {
    this.getGoodsClass();
  },
  methods: {
    async getGoodsClass() {
      const res = await GoodsClass();
      if (res.code * 1 === 1) {
        res.data.unshift({
          class_name: "全部",
          id: 0,
        });
        this.dataList = res.data;
      }
    },
    setOption() {
      this.classId = 0;
      this.active = 0;
      this.twoList = [];
    },
    onClick(index, item) {
      this.active = index;
      this.twoList = this.dataList[index].child_class;
      if (item.id === 0) {
        this.$emit("selectClass", item);
      }
    },
    goToGoods(item) {
      // 跳转至商品分类列表
      this.classId = item.id;
      this.$emit("selectClass", item);
    },
  },
};
</script>

<style lang="less" scoped>
.one-ul-layout {
  width: 110px;
  // box-sizing: border-box;
  .one-ul-layout-li {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 58px;
    font-size: 16px;
    text-align: center;
    font-weight: 500;

    .is-show-mark {
      display: block;
    }
  }
  .is-show-color {
    color: #ed301d;
    // background-color: #fff;
  }
}
.two-ul-layout {
  position: absolute;
  right: 0;
  top: 0;
  // padding: 12px 12px 70px 12px;
  .two-ul-li {
    width: 291px;
    margin-right: 12px;
    margin-top: 12px;
    padding: 12px 0 12px 13px;
    border-radius: 15px;
    box-sizing: border-box;
    background-color: #fff;
    .two-ul-title {
      font-weight: 500;
      font-size: 16px;
    }
  }
}
.three-ul-layout {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  .three-ul-li {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    font-size: 14px;
    padding: 5px 10px;
    background-color: #f2f2f2;
    border-radius: 50px;
    margin-right: 13px;
    margin-top: 13px;
  }
  .text-color {
    color: #ed301d;
    background-color: #fce9e5;
  }
  .sort-img {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 68px;
    height: 68px;
    // padding: 4px 10px;
    margin: 0;
    box-sizing: border-box;
    border-radius: 10px;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .sort-text {
    margin-top: 6px;
    font-size: 13px;
  }
}
</style>
