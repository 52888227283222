<template>
  <div class="max-box">
    <van-list
      v-model="loading"
      :finished="finished"
      offset="300"
      :finished-text="finishedText"
      @load="onLoad"
    >
      <van-grid column-num="2" :border="false">
        <template default>
          <div class="goods-box" v-for="(item,index) in list" :key="item.id"   @click="goodsInfo(index)">
            <img :src="item.cover_img" class="goods-img" />
            <div class="goods-info">
              <div class="goods-name">
                <div class="expense" v-if="item.is_expense === 1">消费金</div>
                {{ item.good_name }}
              </div>
              <div class="tag-box">
                <div class="fine-tag common" v-if="item.is_sift === 1">
                  精选
                </div>
                <div class="new-tag common" v-if="item.is_new === 1">新品</div>
              </div>
              <div class="price-box">
                <Price :price="item.price"></Price>
                <Price
                  class="original-price"
                  :price="item.original_price"
                  integerStyle="12"
                  spanColor="#B9BABE"
                ></Price>
              </div>
              <div class="goods-evaluate">
                <img
                  src="@/assets/common/icon-good-opinion.png"
                  class="stars"
                />
                <div>{{ item.score }}分</div>
                <div>|</div>
                <div>{{ item.sales }}人付款</div>
              </div>
            </div>
          </div>
        </template>
      </van-grid>
    </van-list>
    <div class="blank" v-if="showBlank">
      <img src="@/assets/blank/collect-blank-images@2x.png" class="blank-img" />
      <div class="blank-text">暂无商品~</div>
    </div>
  </div>
</template>

<script>
import { goodsList } from "@/api/goods.js";
import { getCouponGoods } from "@/api/shop.js";
export default {
  props: ["shopId"],
  data() {
    return {
      loading: false,
      finished: false,
      formData: {
        is_recom: 0,
        is_new: 0,
        order_by: 1,
        page: 0,
        shop_id: "",
        is_sift: "",
      },
      showBlank: false,
      isRequest: false,
      list: [],
      ids: [],
      isAll: "",
      finishedText: "没有更多了~",
    };
  },
  mounted() {
    this.formData.shop_id = this.shopId;
  },
  methods: {
    goodsInfo(goodsIndex) {
      let goodsInfo = this.list[goodsIndex];
      let goodsId = goodsInfo.id;
      localStorage.setItem("GOODS_ID", goodsId);
      this.$router.push({
        name: "goods",
        query: {
          goodsId: goodsId,
          type: 1,
        },
      });
    },
    getData(data) {
      if (this.isRequest) return;
      this.isRequest = true;
      this.list = [];
      this.isAll = [];
      this.ids = [];
      this.formData.page = 1;
      this.finished = false;
      this.formData.good_name = data.good_name; // 商品名称
      if (data && data.is_new) {
        this.formData.is_new = data.is_new; // 是否是新品
      }
      if (data && data.class_id) {
        this.formData.class_id = data.class_id; // 分类商品（只支持二级分类）
      }
      if (data && data.is_score) {
        this.formData.is_score = data.is_score; // 按评价排序
      }
      if (data && data.shop_id) {
        this.formData.shop_id = data.shop_id; // 商家id
      }
      if (data && data.is_sift) {
        this.formData.is_sift = data.is_sift; // 是否是精选
      }
      if (data && data.order_by) {
        this.formData.order_by = data.order_by; // 1 默认排序 2按销量 3按评价 4价格正序 其余按价格倒序
      }
      this.getGoodsList();
    },
    initCoupon(ids, isAll) {
      if (this.isRequest) return;
      this.isRequest = true;
      this.formData.page = 1;
      this.isAll = isAll;
      this.ids = ids;
      this.list = [];
      this.getCouponGoods();
    },
    onLoad() {
      if (this.isRequest) return;
      this.isRequest = true;
      this.formData.page++;
      if (this.ids.length > 0) {
        this.getCouponGoods();
      } else {
        this.getGoodsList();
      }
    },
    async getGoodsList() {
      const res = await goodsList(this.formData);
      if (res.code === 1) {
        this.list = [...this.list, ...res.data.list];
        if (this.formData.page === 1 && this.list.length <= 0) {
          this.finishedText = "";
          this.showBlank = true;
        } else {
          this.finishedText = "没有更多了~";
          this.showBlank = false;
        }
        if (this.list.length < 20) {
          this.finished = true;
        }
      }
      this.loading = false;
      this.isRequest = false;
    },
    async getCouponGoods() {
      const res = await getCouponGoods({
        shop_id: this.shopId,
        coupon_ids: this.ids,
        is_all: this.isAll,
      });
      if (res.code === 1) {
        this.list = [...this.list, ...res.data.list];
        if (this.formData.page === 1 && this.list.length <= 0) {
          this.finishedText = "";
          this.showBlank = true;
        } else {
          this.finishedText = "没有更多了~";
          this.showBlank = false;
        }
        if (this.list.length < 20) {
          this.finished = true;
        }
      }
      this.loading = false;
      this.isRequest = false;
    },
  },
};
</script>

<style lang="less" scoped>
.max-box {
  margin-top: 10px;
}
.van-list {
  padding: 0 13px;
  margin-bottom: 57px;
}
.van-grid {
  justify-content: space-between;
  .goods-detail {
    &:nth-child(2n + 1) {
      margin: 0 14px 12px 0;
    }
  }
  .goods-detail {
    &:nth-child(2n) {
      margin-bottom: 12px;
    }
  }
}
.goods-box {
  display: flex;
  flex-direction: column;
  width: 189px;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10px;
  .goods-img {
    width: 100%;
    height: 189px;
  }
}
.goods-info {
  flex: 1;
  padding: 10px;
  background-color: #fff;
  box-sizing: border-box;
  .goods-name {
    font-size: 16px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .expense {
    float: left;
    height: 17px;
    padding: 0 4px;
    margin-top: 3px;
    margin-right: 3px;
    color: #fff;
    font-size: 12px;
    background: linear-gradient(91deg, #ff0000 0%, #ff4f13 100%);
    border-radius: 17px;
    line-height: 17px;
  }
  .tag-box {
    display: flex;
    margin-top: 7px;
    .common {
      height: 17px;
      padding: 0 4px;
      border-radius: 3px;
      line-height: 17px;
      font-size: 12px;
    }
    .fine-tag {
      background: linear-gradient(279deg, #2e2a27 0%, #2f2d2e 100%);
      border: 1px solid #2f2d2e;
      color: #e7cbad;
      margin-right: 5px;
    }
    .new-tag {
      border: 1px solid #ed301d;
      color: #ed301d;
      border-radius: 3px;
    }
  }
  .price-box {
    display: flex;
    align-items: center;
    margin: 3px;
    .original-price {
      text-decoration: line-through;
      color: #b9babe;
      margin-left: 5px;
    }
  }
  .goods-evaluate {
    display: flex;
    align-items: center;
    color: #aaaaaa;
    font-size: 13px;
    div {
      margin-left: 6px;
    }
    .stars {
      width: 13px;
      height: 13px;
    }
  }
}
.blank {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .blank-img {
    width: 207px;
    height: auto;
  }
  .blank-text {
    margin-top: 10px;
    font-size: 17px;
    color: #aaaaaa;
  }
}
</style>
