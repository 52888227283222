<template>
  <div class="tab-class">
    <ul class="big-tab">
      <li
        class="one-list"
        :style="{ color: tabIndex === 1 ? '#ED301D' : '#6f6f6f' }"
        :class="{ bottom: tabIndex === 1 }"
        @click="tabChange(1)"
      >
        全部
      </li>
      <li
        class="one-list"
        :style="{ color: tabIndex === 2 ? '#ED301D' : '#6f6f6f' }"
        :class="{ bottom: tabIndex === 2 }"
        @click="tabChange(2)"
      >
        精选
      </li>
      <li
        class="one-list"
        :style="{ color: tabIndex === 3 ? '#ED301D' : '#6f6f6f' }"
        :class="{ bottom: tabIndex === 3 }"
        @click="tabChange(3)"
      >
        上新
      </li>
      <li
        class="one-list"
        :style="{ color: tabIndex === 4 ? '#ED301D' : '#6f6f6f' }"
        :class="{ bottom: tabIndex === 4 }"
        @click="tabChange(4)"
      >
        活动
      </li>
    </ul>
    <ul class="small-tab" v-if="tabIndex !== 4">
      <li
        class="two-list"
        :style="{ color: screen === 1 ? '#ED301D' : 'black' }"
        @click="screenChange(1)"
      >
        综合
      </li>
      <li
        class="two-list"
        :style="{ color: screen === 2 ? '#ED301D' : 'black' }"
        @click="screenChange(2)"
      >
        销量
      </li>
      <li
        class="two-list"
        :style="{ color: screen === 3 ? '#ED301D' : 'black' }"
        @click="screenChange(3)"
      >
        评论
      </li>
      <li
        class="two-list"
        :style="{ color: screen === 4 ? '#ED301D' : 'black' }"
        @click="screenChange(4)"
      >
        <span class="list-text">价格</span>
        <div class="arrow-section">
          <div class="arrow-box arrow-top">
            <img
              src="@/assets/shop-home/red-arrow-top.png"
              class="arrow"
              v-if="screen === 4 && screenStr === 'ShengXu'"
            />
            <img src="@/assets/shop-home/arrow-top.png" class="arrow" v-else />
          </div>
          <div class="arrow-box arrow-bottom">
            <img
              src="@/assets/shop-home/red-arrow-bottom.png"
              class="arrow"
              v-if="screen === 4 && screenStr === 'DaoXu'"
            />
            <img
              src="@/assets/shop-home/arrow-bottom.png"
              class="arrow"
              v-else
            />
          </div>
        </div>
      </li>
      <!-- <li class="two-list">单排列表</li>-->
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabIndex: 1,
      screen: 1,
      screenStr: "",
    };
  },
  mounted() {},
  methods: {
    tabChange(type) {
      if (this.tabIndex !== type) {
        this.tabIndex = type;
        this.screen = 1;
        this.screenStr = "";
        this.$emit("tabChange", type);
      }
    },
    screenChange(type) {
      if (this.screen !== type && type !== 4) {
        this.screen = type;
        this.$emit("screenChange", this.screen);
      }
      if (type === 4) {
        this.screen = type;
        if (this.screenStr === "" || this.screenStr === "DaoXu") {
          this.screenStr = "ShengXu";
          this.$emit("screenChange", 4);
        } else {
          this.screenStr = "DaoXu";
          this.$emit("screenChange", 5);
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.tab-class {
  position: sticky;
  top: 49px;
  background-color: #fff;
  z-index: 10;
}
.big-tab {
  display: flex;
  height: 57px;
  .one-list {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    &.bottom::after {
      content: " ";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0px;
      height: 2px;
      width: 36px;
      background: #ff282b;
    }
  }
}
.small-tab {
  display: flex;
  height: 57px;
  .two-list {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    .list-text {
      padding-right: 6px;
    }
    .arrow-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      padding-top: 2px;
    }
    .arrow-box {
      height: 10px;
    }
    .arrow-top {
      display: flex;
    }
    .arrow-bottom {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
    .arrow {
      width: 9px;
      height: 9px;
    }
  }
}
</style>